.promotion-section ul.nav.nav-tabs {
    justify-content: space-around;
    z-index: 1;
    border-bottom: 0px;
    height: 30px;
    padding: 4px 0 0 0;
    margin-bottom: 15px;
}

.nav-link{ padding: 0; color:  #708DBB;}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
}
.nav-link:focus, .nav-link:hover {
    color: #708DBB;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    isolation: isolate;
    border-color: transparent;
}

.promotion-section {
    position: relative;
}

.promotion-section .bg--tab--blue {
    position: absolute;
    width: 100%;
    top: 0;
}

@media screen and (max-width: 789px){
    .promotion-section ul.nav.nav-tabs{
        height: 15px;
        padding: 1px 0 0 0;
    }
}

@media screen and (max-width: 470px) {
    .promotion-section .bg--tab--blue {
        height: 18px;
    }
}

@media screen and (max-width: 315px){
    .mb--right img {
        width: 38px;
        margin-top: 4px;
    }
}