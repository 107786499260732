@font-face {
  font-family: "sukhumvitSetMedium";
  /*Can be any text*/
  src: local("sukhumvitSet-Medium"),
    url("./Fonts/sukhumvit/ttf/SukhumvitSet-Medium.ttf") format("truetype");
}

* {
  font-family: sukhumvitSetMedium;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
}

.wrapper--bg {
  /* background: url("/src/Images/bg.png") no-repeat fixed center; */
  background: url("/src/Images/bg.png") no-repeat fixed center;
  color: #fff;
  background-color: #0c1036;
  position: fixed;
  /* overflow-y: scroll; */
  height: 100%;
  background: linear-gradient(180deg, rgba(13, 17, 55, 1) 0%, rgba(37, 43, 68, 1) 93%, rgba(59, 59, 63, 1) 100%);
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.wrapper .login {
  width: 320px;
  margin: auto;
  text-align: center;
}

.btn.btn--contact {
  /* background-color: #fff; */
  color: #000;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  width: 100%;
  margin: 10px 0px;
  background: url("/src/Images/btn-bg-white.png") no-repeat center;
  background-size: 100%;
}

.btn.btn--contact div {
  display: flex;
  justify-content: start;
  width: 220px;
}

.btn.btn--contact img {
  width: 30px;
  height: 30px;
  margin: 11px;
}

/*Header*/
.wrapper .header .section--img {
  position: relative;
}

.wrapper .header .section--img img.header-bg {
  height: 84px;
  width: 100%;
}

.wrapper .header .section--img img.header-play-games {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 390px;
}

.wrapper .header .section--logo {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: left;
}

.wrapper .header .section--logo .section--username {
  color: #000;
  position: relative;
  width: 21%;
  padding: 24px 0;
}

.wrapper .header .section--logo .section--username .btn-login,
.wrapper .header .section--logo .section--username .btn-register {
  /* position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(0,-50%); */
  cursor: pointer;
  display: inline-block;
  width: 47%;
}

.wrapper .header .section--logo .section--username .btn-login img,
.wrapper .header .section--logo .section--username .btn-register img {
  width: 100%;
}

.wrapper .header .section--logo .section--username .btn-register {
  margin-right: 5px;
}

.wrapper .header .section--logo .section--username .username--money img {
  width: 13px;
  height: 13px;
  margin: 20px 10px;
}

.wrapper .header .section--logo .section--username .username--money {
  display: flex;
  justify-content: center;
}

img.header-stamp {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.wrapper .header .header--right .line {
  /* background: url("/src/Images/btn-bg-white.png") no-repeat center;
  background-size: 177px 35px;
  padding: 0;
  width: 177px;
  text-align: center;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-right: 5px; */
  position: relative;
  width: 200px;
  right: 5px;
}

.wrapper .header .header--right .line img.imgHeaderBgBtnLine {
  position: absolute;
  width: 100%;
  height: 100%;
}

.wrapper .header .header--right .line p {
  position: absolute;
  top: 55%;
  left: 55%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #062D6A;
  font-weight: 600;
  width: 115px;
  text-align: center;
}

.wrapper .header .header--right .line img.imgHeaderLines {
  position: absolute;
  width: 20px;
  z-index: 1;
  left: 33px;
  top: 53%;
  transform: translate(0, -50%);
}

.header--right {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  display: flex;
  justify-content: end;
}

.wrapper .header .header--right .line img {
  /* width: 20px;
  height: 20px;
  padding: 10px; */
}

/* .wrapper .header .header--right .line p {
  margin: 10px 0;
  font-size: 14px;
} */

.wrapper .header .header--right .btn--notify,
.wrapper .header .header--right .btn--burgerbar {
  width: 38px;
  height: 38px;
  margin: 0 3px;
  cursor: pointer;
}

/*Home*/
.home {
  background: url("/src/Images/bg.png") !important;
  background-size: 100% 100% !important;
  background-attachment: fixed !important;
  background-position: center !important;
}

.home--section .pc {
  display: flex;
  justify-content: center;
  width: 950px;
  margin: auto;
}

.home--section .pc .left {
  width: 583px;
  padding-right: 50px;
  margin-bottom: 9rem;
}

.mb--right {
  padding-top: 10px;
}

/* 
.show--games .row  {
  text-align: center;
}

.show--games .row .games {
  display: inline-block;
  margin: 10px;
}

.show--games--title {
  position: relative;
  margin: 10px;
}

.show--games--title .click {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.show--games--title .click a {
  color: #fff;
  text-decoration: none;
  padding: 0 21px 3px;
  cursor: pointer;
}
.show--games--title .click a:hover {
  color: #708DBB;
} */
/* 
.all--games--section img {
  width: 104px;
  margin: 0 6px;
} */


.pc {
  display: block;
}

.mobile {
  display: none;
}

.w-100 {
  width: 100%;
}

.pc .section--item {
  max-width: 1280px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  height: 84px;
  z-index: 1;
}

.light-car {
  display: none;
}

.all--games--section a img {
  width: 120px;
}

.all--games--section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.home--section h3 {
  margin-top: 29px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}

/* .home--section .pc .right {
  width: 242px;
} */
.home--section .pc .right {
  width: 320px;
}

.home--section .pc .right a img {
  width: 100%;
  margin-bottom: 10px;
}


a.go-to-homepage {
  width: calc(100% - 87px);
  height: 82px;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 6;
}

a.go-to-promotion {
  width: 87px;
  height: 82px;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 6;
  right: 0;
}



.modal.showmenu--hamburgerbar--modal {
  background-color: rgba(8, 2, 71, .7) !important;
  margin-top: 84px;
}

.modal.showmenu--hamburgerbar--modal .modal-content {
  background-color: transparent;
  border: 0;
}

.modal-backdrop.show {
  opacity: 0 !important;
}

.show--notification--modal .modal-dialog {
  max-width: 100%;
  position: fixed;
  width: 100%;
  height: 100%;
}

.show--notification--modal .wrapper--notification {
  width: 350px;
  margin: 0 auto;
}

.show--notification--modal .modal-content {
  height: 100%;
  background-color: #000;
  background-image: linear-gradient(to top left, rgb(0, 5, 44) 0, rgb(0, 5, 44) 30%, rgb(0, 39, 255) 170%);
  color: #fff;
  font-weight: 600;
}

span.notification--close {
  position: absolute;
  right: 0;
  padding: 10px;
  font-size: 30px;
  font-weight: 100;
  z-index: 1;
  cursor: pointer;
}

.modal.show.show--notification--modal .modal-dialog {
  margin: 0;
}

.show--notification--modal .menu--notification--img img {
  width: 50px;
  padding: 10px;
  height: 46px;
}

.menu--notification--img {
  background-color: rgba(0, 39, 255, .6);
  border: 1px solid rgba(0, 206, 255, 6);
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 50%;
  text-align: center;
  margin: 10px;
}

.show--notification--modal img.header-bg-nofi {
  position: absolute;
  left: 0;
  width: 100%;
  height: 75px;
}

.notification--row.row {
  margin-top: 100px;
}

.menu--notification.col-3 {
  text-align: center;
}

span.notification--title {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
  color: #000;
  font-size: 25px;
}


.header-play-games-section-top {
  width: 386px;
  height: 85px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
}

/*
*/
img.img--logo {
  width: 150px;
}

.logo-as {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-top: 0.5rem;
}

@media screen and (max-width: 1200px) {
  .wrapper .header .section--logo .section--username img {
    width: 108px;
    margin-top: 5px;
    margin-left: 3px;
  }
}

@media screen and (max-width: 1122px) {
  .wrapper .header .section--logo .section--username img {
    width: 95px;
  }
}

@media screen and (max-width: 1099px) {
  .wrapper .header .section--logo .section--username {
    width: 19%;
  }
}

@media screen and (max-width: 1070px) {
  .wrapper .header .section--logo .section--username img {
    width: 87px;
  }
}

@media screen and (max-width: 1030px) {
  .wrapper .header .section--logo .section--username img {
    width: 75px;
  }

  img.img--logo {
    width: 100px;
    margin: 32px 3px 0px 3px;
  }
}

@media screen and (max-width: 1000px) {
  .home--section .pc {
    width: 100%;
  }

  .home--section .pc .left {
    width: 510px;
    margin-right: 20px;
    padding-right: 0;
  }

  /* .home--section .pc .right {
    width: 240px;
  } */
  .home--section .pc .right {
    width: 300px;
  }

  .home--section .pc .right img {
    width: 100%;
  }

  /* .home--section .pc .right img,
  .show--games .show--games--title img,
  .show--games .row .games img {
    width: 100%;
  } */

  /* .all--games--section img {
    width: 90px;
    margin: 0 6px;
  } */
  /* 
  .show--games--title .click a {
    padding: 0 21px 5px;
    font-size: 14px;
  }
  .show--games .row .games {
    width: 140px;
  } */

  .wrapper .header .header--right .line {
    background-size: 125px 28px;
    width: 125px;
  }

  /* .wrapper .header .header--right .line p {
    margin: 12px 0;
    font-size: 11px;
  } */
  .wrapper .header .header--right .line img {
    /* width: 17px;
    height: 17px;
    padding: 11px 4px 0; */
  }

  .wrapper .header .header--right .btn--notify,
  .wrapper .header .header--right .btn--burgerbar {
    width: 32px;
    height: 32px;
    margin: 4px 3px 0;
  }

  .wrapper .header .section--logo .section--username img {
    width: 71px;
    margin-left: 0;
  }

  .wrapper .header .header--right .line p {
    font-size: 11px;
  }

  .wrapper .header .header--right .line img.imgHeaderLines {
    width: 17px;
    left: 17px;
  }

  .wrapper .header .header--right .line p {
    left: 59%;
    width: 75px;
  }
}

@media screen and (max-width: 964px) {
  img.img--logo {
    width: 90px;
    margin: 33px 3px 0px 3px;
  }

  .wrapper .header .section--logo .section--username {
    width: 17%;
  }
}

@media screen and (max-width: 960px) {
  .wrapper .header .section--logo .section--username img {
    width: 68px;
    margin-left: 0;
  }
}

@media screen and (max-width: 945px) {
  .wrapper .header .section--logo .section--username img {
    width: 60px;
  }

  img.img--logo {
    width: 90px;
    margin: 33px 3px 0px 3px;
  }
}

@media screen and (max-width: 895px) {
  .home--section {
    padding: 0 40px;
  }

  .home--section .pc .right {
    margin-left: -25px;
  }
}

@media screen and (max-width: 875px) {
  img.img--logo {
    width: 80px;
    margin: 22px 3px 0px 3px;
  }
}

@media screen and (max-width: 860px) {
  .home--section {
    width: 100%;
  }

  .home--section .pc {
    justify-content: center;
  }

  .wrapper .header .section--img img.header-bg {
    height: 60px;
  }

  .wrapper .header .section--img img.header-play-games {
    width: 285px;
  }

  img.img--logo {
    width: 100px;
  }

  .section--username .btn-login img {
    width: 75px;
  }

  /* .home--section .pc .right {
    width: 200px;
  } */
  .home--section .pc .right {
    width: 300px;
  }

  .header--right {
    top: 37%;
  }

  .modal.showmenu--hamburgerbar--modal {
    margin-top: 61px;
  }


  .header-play-games-section-top {
    width: 284px;
    height: 62px;
  }

  .wrapper .header .section--logo .section--username img {
    width: 70px;
    margin-top: -16px;
  }

  /* img.img--logo {
    width: 100px;
    height: 67px;
  } */

  .wrapper .header .header--right .line {
    height: 26px;
    margin-top: 5px;
  }

  .wrapper .header .header--right .line img.imgHeaderLines {
    width: 15px;
    left: 17px;
    top: 50%;
  }

  img.img--logo {
    width: 80px;
    margin: 22px 3px 0px 3px;
  }

  .wrapper .header .section--logo .section--username {
    width: 20%;
  }
}

@media screen and (max-width: 789px) {
  .home {
    /* background-size: 100%; */
  }

  .all--games--section a img {
    width: 50px;
  }

  .wrapper .header .section--img img.header-play-games {
    width: 250px;
  }

  .wrapper .header .header--right .line {
    background-size: 95px 23px;
    width: 95px;
  }

  .wrapper .header .section--img img.header-bg {
    height: 50px;
  }

  .home--section .pc .left {
    width: 288px;
    margin-right: 15px;
  }

  /* .all--games--section {
    text-align: center;
  }

  .all--games--section img {
    width: 45px;
    margin: 0 6px;
  } */
  /* .show--games .row .games {
    width: 76px;
  }
  .show--games--title .click a {
    font-size: 9px;
  } */
  .home--section .pc h3 {
    font-size: 12px;
    padding-left: 10px;
  }

  .home--section .pc .right {
    width: 165px;
  }

  .home--section .pc {
    justify-content: center;
  }

  /* img.img--logo {
    width: 80px;
    height: 52px;
  } */
  img.img--logo {
    width: 80px;
    margin-top: -4px;
    height: 60px;
  }

  /* .wrapper .header .header--right .line p {
    font-size: 8px;
  } */
  .wrapper .header .header--right .line img {
    /* width: 15px;
    height: 15px; */
  }

  .wrapper .header .header--right .btn--notify,
  .wrapper .header .header--right .btn--burgerbar {
    width: 25px;
    height: 25px;
    margin: 5px 3px 0;
  }

  .header--right {
    right: 3px;
    top: 29%;
  }

  .section--username .btn-login img {
    width: 60px;
  }

  ul.nav.nav-tabs {
    padding: 0 0 0;
    font-size: 10px;
  }

  .modal.showmenu--hamburgerbar--modal {
    margin-top: 52px;
  }

  .header-play-games-section-top {
    width: 248px;
    height: 55px;
  }

  .wrapper .header .section--logo .section--username img {
    margin-top: -21px;
  }

  .wrapper .header .header--right .line {
    background-size: unset;
    width: 100px;
    height: 21px;
    margin-top: 6px;
  }

  .wrapper .header .header--right .line p {
    font-size: 8px;
  }

  .wrapper .header .header--right .line img.imgHeaderLines {
    width: 12px;
    left: 19px;
  }

  .games.mb-2.col-md-4.col-6 {
    width: 33.33%;
    padding: 0 3px;
  }

  img.img--logo {
    width: 80px;
    margin: 22px 3px 0px 3px;
    height: auto;
  }
}

@media screen and (max-width: 750px) {
  .wrapper .header .section--logo .section--username img {
    margin-top: -21px;
    width: 60px;
  }
}

@media screen and (max-width: 700px) {
  .wrapper .header .section--logo .section--username img {
    width: 63px;
  }

  .wrapper .header .section--logo .section--username .btn-register {
    margin-right: 0;
  }
}

@media screen and (max-width: 669px) {
  img.img--logo {
    width: 60px;
    margin: 12px 0;
    height: auto;
  }
}

@media screen and (max-width: 665px) {
  .wrapper .header .section--logo .section--username img {
    width: 57px;
  }
}

@media screen and (max-width: 650px) {
  .wrapper .header .section--logo .section--username img {
    width: 56px;
  }
}

@media screen and (max-width: 634px) {
  .wrapper .header .section--logo .section--username img {
    width: 50px;
    height: 17px;
  }

  img.img--logo {
    width: 72px;
    margin-top: -4px;
    height: 60px;
  }
}

/* 
@media screen and(max-width: 638px){
  .wrapper .header .section--logo .section--username img {
    width: 51px;
  }
  img.img--logo {
    width: 66px;
    margin-top: 0;
    height: 48px;
  }
}

@media screen and(max-width: 632px){
  img.img--logo {
    width: 68px;
    margin-top: 3px;
    height: 45px;
  }
  .wrapper .header .section--logo .section--username img {
    width: 55px;
  }
}

@media screen and(max-width: 605px){
  img.img--logo {
    width: 55px;
    margin-top: 8px;
    height: 38px;
    margin-right: -3px;
  }
  .wrapper .header .section--logo .section--username img {
    width: 55px;
  }
} */
/* @media screen and(max-width: 627px){
  img.img--logo {
    width: 63px;
    margin-top: -4px;
    height: 60px;
  }
} */

@media screen and (max-width: 592px) {
  img.img--logo {
    width: 45px;
    margin: 15px 0;
    height: auto;
  }

  .wrapper .header .section--logo .section--username {
    width: 16%;
  }

  .wrapper .header .section--logo .section--username img {
    height: 13px;
  }
}

@media screen and (max-width: 590px) {

  /* .show--games .row .games {
    width: 60px;
    margin: 1px 7px;
  }
  .show--games--title {
    margin: 5px;
  } */
  .home--section .pc h3 {
    margin: 6px 0;
  }

  .home {
    /* background-size: 100% 100%; */
  }

  .wrapper .header .section--img img.header-play-games {
    width: 200px;
  }

  .wrapper .header .header--right .line img {
    /* width: 10px;
    height: 10px;
    padding: 13px 4px 0; */
  }

  .wrapper .header .header--right .line {
    background-size: 83px 19px;
    width: 83px;
  }

  /* .wrapper .header .header--right .line p {
    font-size: 7px;
  } */
  .wrapper .header .header--right .line {
    margin-right: 2px;
  }

  .wrapper .header .header--right .btn--notify,
  .wrapper .header .header--right .btn--burgerbar {
    width: 21px;
    height: 21px;
    margin: 7px 0px 0;
  }

  .section--username .btn-login img {
    width: 50px;
  }

  .wrapper .header .section--img img.header-bg {
    height: 43px;
  }

  /* .home--section .pc .right {
    width: 130px;
  } */
  .home--section .pc .right {
    width: 160px;
    margin-left: -10px;
  }

  .pc .section--item {
    height: 47px;
  }

  .header--right {
    top: 46%;
  }


  .modal.showmenu--hamburgerbar--modal {
    margin-top: 43px;
  }

  .header-play-games-section-top {
    width: 198px;
    height: 45px;
  }

  /* img.img--logo {
    width: 80px;
    height: 45px;
  } */

  .logo-as {
    display: flex;
    align-items: center;
    margin-right: 0rem;
    margin-top: 0.5rem;
  }

  img.img--logo {
    width: 55px;
    margin-top: 10px;
    height: 38px;
  }

  .wrapper .header .section--logo .section--username img {
    width: 58px;
    margin-top: -29px;
    margin-right: 2px;
  }

  .wrapper .header .section--logo .section--username img {
    width: 50px;
    margin-top: -29px;
  }

  .wrapper .header .header--right .line img.imgHeaderLines {
    left: 10px;
  }

  img.img--logo {
    width: 45px;
    height: auto;
  }

  .wrapper .header .section--logo .section--username img {
    height: auto;
  }

  .wrapper .header .section--logo .section--username {
    width: 20%;
  }
}

/*@media screen and (max-width: 555px) {
  .wrapper .header .section--logo .section--username img {
    width: 43px;
    height: 15px;
  }
}*/

/*@media screen and (max-width: 526px) {
  .wrapper .header .section--logo .section--username img {
    width: 40px;
  }
  img.img--logo {
    width: 60px;
    margin: 0px 0 0 0;
    height: 47px;
  }
}*/

/*@media screen and (max-width: 500px){
  .wrapper .header .section--logo .section--username img {
    width: 38px;
  }
  img.img--logo {
    width: 50px;
    margin: 2px 0 0 0;
    height: 40px;
  }
}*/



/*@media screen and (max-width: 466px) {
  img.img--logo {
    width: 47px;
  }
}*/

/*@media screen and (max-width: 433px) {
  img.img--logo {
    width: 37px;
    height: 30px;
    margin-top: 6px;
  }
}*/
/*@media screen and (max-width: 442px) {
  img.img--logo {
    width: 42px;
    margin-top: 5px;
    height: 28px;
  }
  .wrapper .header .section--logo .section--username {
    padding: 21px 0;
  }
  .wrapper .header .section--logo .section--username img {
    width: 38px;
  }
}*/
/*@media screen and (max-width: 420px) {
  .wrapper .header .section--logo .section--username img {
    width: 33px;
  }
  img.img--logo {
    width: 48px;
    height: 30px;
    margin: 5px -2px 0 -2px;
  }
}*/

/*@media screen and (max-width: 422px){
  .wrapper .header .section--logo .section--username img {
    height: 11px;
  }
  img.img--logo {
    width: 37px;
    margin-top: 10px;
    height: auto;
  }
}*/

@media screen and (max-width: 550px) {

  .section--img .pc,
  .home--section .pc {
    display: none;
  }

  .section--img .mobile,
  .home--section .mobile {
    display: block;
  }

  .wrapper .header .section--img img.header-bg {
    height: 66px;
  }

  .section--username .btn-login img {
    width: 94px;
    height: 34px;
  }

  .box-mb {
    width: 450px;
    margin: auto;
    padding-bottom: 5rem;
  }

  .mobile .mb--section {
    display: flex;
    justify-content: space-between;
  }

  img.mb--header-bg {
    position: absolute;
    text-align: center;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 65px;
  }

  .mobile .mb--section {
    display: flex;
    justify-content: space-between;
    z-index: 4;
    position: relative;
    padding: 2px 10px 10px;
  }

  img.img--logo {
    width: 160px;
    /* margin-top: -15px; */
    height: auto;
  }

  .mb--section .mb--login .btn-login {
    margin-top: 6px;
  }

  .mb--login {
    width: 22%;
  }

  .home--section .pc {
    margin-top: -10px;
  }

  .home .home--section {
    background-color: #0c1036;
  }

  .home .home--section .mobile {
    /* background: url('/src/Images/bg-mobile.png') no-repeat fixed center; */
    background: url('/src/Images/bg-mobile.png') no-repeat fixed center;
    background-size: 100% 100%;
    /* height: 100vh; */
    /* background-color: #0c1036; */
    padding-bottom: 50px;
  }

  .mb--games div {
    display: flex;
    justify-content: space-between;
  }

  .mobile .bg--games {
    position: relative;
    width: 310px;
    height: 148px;
    margin: auto;
  }

  .mobile .bg--games img.img--gameshit--bg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 190px;
    width: 310px;
  }

  .splide__pagination {
    bottom: -1.7em !important;
  }

  .mobile .bg--games .games {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    z-index: 1;
    transform: translate(-50%, -50%);
    padding: 30px 15px 15px;
  }

  .mb--games {
    height: 240px;
  }

  .footer .footer--section .mobile .menu--section {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .footer .footer--section .mobile .menu--section img {
    width: 100%;
  }

  .mobile .mb--title-top,
  .mobile .mb--promotion,
  .mb--title--category {
    text-align: center;
  }

  .mobile .mb--title-top {
    position: relative;
    height: 23px;
    margin-bottom: 20px;
  }

  .mobile .mb--title-top .bg--tab--blue {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    padding: 0 5px;
  }

  marquee.marquee--text {
    width: 90%;
    z-index: 2;
    position: absolute;
    padding: 5px 5px;
    display: block;
    margin-left: 12px;
    font-size: 14px;
  }

  .mobile .mb--promotion {
    text-align: center;
    margin: 0 0 35px 0;
  }

  .mb--contact {
    background: url("/src/Images/btn-bg-white.png") no-repeat center;
    position: relative;
    background-size: 100%;
    width: 300px;
    height: 42px;
    margin: 20px auto 0;
    cursor: pointer;
    z-index: 3;
  }

  .mb--contact .mb--contact--line {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    color: #062D6A;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .mb--contact .mb--contact--line p {
    margin-bottom: 3px;
    padding-bottom: 0;
    margin: 15px 0;
    font-weight: bold;
  }

  .mb--contact .mb--contact--line img {
    width: 30px;
    height: 30px;
    margin: 12px 8px;
  }

  .mb--games-tab-title h5 {
    margin: 0;
    padding: 0 0 0 33px;
    font-size: 16px;
  }

  .mb--games-tab-title a {
    color: yellow;
    padding-right: 35px;
    font-size: 13px;
  }

  .mb--games-tab-title {
    margin: 15px 0 7px;
  }

  /* .mb--all--games--section {
    margin: 10px 0 20px;
  } */

  .mb--title-top {
    margin: 0px 10px 5px;
  }

  .footer .mobile {
    display: block;
    /* z-index: 2;
    position: absolute; */
  }

  .mb--contact {
    margin-bottom: 145px;
  }

  /* .home{ height: auto; } */
  .wrapper--bg {
    background: linear-gradient(180deg, rgba(13, 17, 55, 1) 54%, rgba(13, 17, 54, 1) 57%, rgba(59, 59, 63, 1) 100%);
  }

  .footer .mobile .playgame--menu--bar {
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;
  }

  .footer--section {
    z-index: 5;
    position: relative;
  }

  .light-car {
    position: fixed;
    bottom: 52px;
    z-index: 0;
    /* display: block; */
  }

  /* .mb--games, .mb--all--games--section{
    z-index: 4;
    position: relative;
  } */


  img.game--img {
    width: 100%;
  }

  .mb--all--games--section {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0rem 2rem;

  }

  .mb--promotion img {
    width: 85%;
  }

  .modal.showmenu--hamburgerbar--modal {
    margin-top: 65px;
  }

  .show--notification--modal .wrapper--notification {
    width: 310px;
  }

  .menu--notification--img {
    margin: 0px 0 10px 0;
  }


  .mb--home--login {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .mb--home--login div {
    margin: 7px 10px 20px;
  }

  .home--section {
    padding: 0 0px;
  }

  .mb--home--login div img {
    width: 160px;
  }

  .mb--login .line {
    width: 120px;
    text-align: center;
    position: relative;
    font-size: 12px;
  }

  .mb--login .line a {
    /* display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 15px 0;
    text-decoration: none;
    color: #000;
    margin: 2px 0; */
  }

  .mb--login img.imgHeaderLines {
    width: 20px;
    height: 20px;
    margin: -1px 5px 0 0;
  }

  .mb--login .imgHeaderBgBtnLine {
    /* width: 100%; */
    height: 30px;
    /* border-radius: 20px; */
  }

  .mb--login .line a p {
    margin-bottom: 0;
  }

  .mb--login {
    width: 22%;
    margin: 7px 0;
  }
}

@media screen and (max-width: 455px) {
  .box-mb {
    width: 400px;
  }
}

@media screen and (max-width: 414px) {
  .wrapper .header .section--img img.header-bg {
    height: 66px;
  }

  .section--username .btn-login img {
    width: 94px;
    height: 34px;
  }

  .mobile .mb--section {
    display: flex;
    justify-content: space-between;
  }

  img.mb--header-bg {
    position: absolute;
    text-align: center;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 65px;
  }

  .mobile .mb--section {
    display: flex;
    justify-content: space-between;
    z-index: 4;
    position: relative;
    padding: 10px;
  }

  img.img--logo {
    width: 150px;
    /* margin-top: -15px; */
    height: auto;
  }

  .mb--section .mb--login .btn-login {
    margin-top: 6px;
  }

  .mb--login {
    width: 22%;
  }

  .home--section .pc {
    margin-top: -10px;
  }

  .home .home--section {
    background-color: #0c1036;
  }

  .home .home--section .mobile {
    /* background: url('/src/Images/bg-mobile.png') no-repeat fixed center; */
    background: url('/src/Images/bg-mobile.png') no-repeat fixed center;
    background-size: 100% 100%;
    /* height: 100vh; */
    /* background-color: #0c1036; */
    padding-bottom: 50px;
  }

  .mb--games div {
    display: flex;
    justify-content: space-between;
  }

  .mobile .bg--games {
    position: relative;
    width: 310px;
    height: 148px;
    margin: auto;
  }

  .mobile .bg--games img.img--gameshit--bg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 190px;
    width: 310px;
  }

  .splide__pagination {
    bottom: -1.7em !important;
  }

  .mobile .bg--games .games {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    z-index: 1;
    transform: translate(-50%, -50%);
    padding: 30px 15px 15px;
  }

  .mb--games {
    height: 240px;
  }

  .footer .footer--section .mobile .menu--section {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .footer .footer--section .mobile .menu--section img {
    width: 100%;
  }

  .mobile .mb--title-top,
  .mobile .mb--promotion,
  .mb--title--category {
    text-align: center;
  }

  .mobile .mb--title-top {
    position: relative;
    height: 23px;
    margin-bottom: 20px;
  }

  .mobile .mb--title-top .bg--tab--blue {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    padding: 0 5px;
  }

  marquee.marquee--text {
    width: 90%;
    z-index: 2;
    position: absolute;
    padding: 5px 5px;
    display: block;
    margin-left: 12px;
    font-size: 14px;
  }

  .mobile .mb--promotion {
    text-align: center;
    margin: 0 0 35px 0;
  }

  .mb--contact {
    background: url("/src/Images/btn-bg-white.png") no-repeat center;
    position: relative;
    background-size: 100%;
    width: 300px;
    height: 42px;
    margin: 20px auto 0;
    cursor: pointer;
    z-index: 3;
  }

  .mb--contact .mb--contact--line {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    color: #062D6A;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .mb--contact .mb--contact--line p {
    margin-bottom: 3px;
    padding-bottom: 0;
    margin: 15px 0;
    font-weight: bold;
  }

  .mb--contact .mb--contact--line img {
    width: 30px;
    height: 30px;
    margin: 12px 8px;
  }

  .mb--games-tab-title h5 {
    margin: 0;
    padding: 0 0 0 33px;
    font-size: 16px;
  }

  .mb--games-tab-title a {
    color: yellow;
    padding-right: 35px;
    font-size: 13px;
  }

  .mb--games-tab-title {
    margin: 15px 0 7px;
  }

  /* .mb--all--games--section {
    margin: 10px 0 20px;
  } */

  .mb--title-top {
    margin: 0px 10px 5px;
  }

  .footer .mobile {
    display: block;
    /* z-index: 2;
    position: absolute; */
  }

  .mb--contact {
    margin-bottom: 145px;
  }

  /* .home{ height: auto; } */
  .wrapper--bg {
    background: linear-gradient(180deg, rgba(13, 17, 55, 1) 54%, rgba(13, 17, 54, 1) 57%, rgba(59, 59, 63, 1) 100%);
  }

  .footer .mobile .playgame--menu--bar {
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;
  }

  .footer--section {
    z-index: 5;
    position: relative;
  }

  .light-car {
    position: fixed;
    bottom: 52px;
    z-index: 0;
    /* display: block; */
  }

  /* .mb--games, .mb--all--games--section{
    z-index: 4;
    position: relative;
  } */


  img.game--img {
    width: 100%;
  }

  .mb--all--games--section {
    text-align: center;
  }

  .mb--promotion img {
    width: 85%;
  }

  .modal.showmenu--hamburgerbar--modal {
    margin-top: 65px;
  }

  .show--notification--modal .wrapper--notification {
    width: 310px;
  }

  .menu--notification--img {
    margin: 0px 0 10px 0;
  }


  .mb--home--login {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .mb--home--login div {
    margin: 7px 10px 20px;
  }

  .home--section {
    padding: 0 0px;
  }

  .mb--home--login div img {
    width: 160px;
  }

  .mb--login .line {
    width: 80px;
    text-align: center;
    position: relative;
    font-size: 12px;
  }

  .mb--login .line a {
    /* display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 15px 0;
    text-decoration: none;
    color: #000;
    margin: 2px 0; */
  }

  .mb--login img.imgHeaderLines {
    width: 20px;
    height: 20px;
    margin: -1px 5px 0 0;
  }

  .mb--login .imgHeaderBgBtnLine {
    /* width: 100%; */
    height: 30px;
    /* border-radius: 20px; */
  }

  .mb--login .line a p {
    margin-bottom: 0;
  }

  .mb--login {
    width: 22%;
    margin: 7px 0;
  }
}

@media screen and (max-width: 400px) {
  .wrapper .header .header--right .line {
    background-size: 56px 11px;
    width: 56px;
  }

  .box-mb {
    width: 350px;
  }

  /* .wrapper .header .header--right .line p {
    font-size: 5px;
  } */
  .wrapper .header .header--right .line img {
    /* width: 7px;
    height: 7px;
    padding: 12px 4px 0; */
  }

  .wrapper .header .header--right .btn--notify,
  .wrapper .header .header--right .btn--burgerbar {
    width: 15px;
    height: 15px;
  }

  .section--username .btn-login img {
    width: 30px;
  }

  /* img.img--logo {
    width: 109px;
  } */
  .home--section .pc .right {
    width: 90px;
  }


  .menu--hamburger.col-6 {
    width: 148px;
  }

  .wrapper--section--hamburger {
    width: 295px;
  }
}

@media screen and (max-width: 395px) {
  .mb--login img.imgHeaderLines {
    width: 15px;
    height: 15px;
    margin: 0px 5px 0 0;
  }

  .mb--login .line a {
    font-size: 10px;
    margin: 0px 0;
  }

  .mb--login .line {
    width: 100px;
  }

  .mb--login .imgHeaderBgBtnLine {
    /* height: 25px; */
  }

  .mb--login {
    margin: 6px 0;
  }
}

@media screen and (max-width: 365px) {

  /* .mb--login .imgHeaderBgBtnLine {
    border-radius: 20px;
  } */
  .mb--login .line a {
    font-size: 8px;
    margin: 11px 0;
  }

  .mb--login .line {
    width: 85px;
  }
}

@media screen and (max-width: 360px) {
  .mb--home--login div img {
    width: 150px;
  }

  .mb--home--login div {
    margin: 7px 5px 20px;
  }
}

@media screen and (max-width: 350px) {
  .mb--login .imgHeaderBgBtnLine {
    /* width: 100%; */
    height: 26px;
    /* border-radius: 20px; */
  }

  .mb--login {
    margin: 7px 0;
  }

  .box-mb {
    width: 300px;
  }
}

@media screen and (max-width: 338px) {

  /* .mb--all--games--section a img {
    margin: 0 3px;
  } */
  /* .home {
    margin-bottom: 360px;
  } */
  .mobile .bg--games {
    width: 250px;
    height: 109px;
  }

  .mobile .bg--games .games img {
    width: 100%;
    height: 100%;
  }

  .mobile .bg--games img.img--gameshit--bg {
    width: 250px;
    height: 150px;
  }

  .mb--contact {
    width: 250px;
  }

  .mb--contact .mb--contact--line img {
    width: 23px;
    height: 23px;
    margin: 14px 5px;
  }

  .mb--contact .mb--contact--line {
    font-size: 14px;
  }

  .home {
    /* margin-bottom: 320px; */
    /* margin-bottom: 263px; */
  }
}

@media screen and (max-width: 338px) {
  img.img--logo {
    width: 150px;
    margin-top: -13px;
  }
}

@media screen and (max-width: 326px) {
  .mb--right a img {
    width: 35px;
    margin: 5px 0;
  }
}

@media screen and (max-width: 330px) {
  .mb--home--login div img {
    width: 130px;
  }
}

@media screen and (max-width: 312px) {
  .mb--contact {
    margin-bottom: 124px;
  }

  img.btn--notify,
  img.btn--burgerbar {
    width: 36px;
    margin-top: 4px;
  }

  .mb--login {
    margin: 9px 0 0 -5px;
  }

  .mb--login img.imgHeaderLines {
    width: 15px;
    height: 15px;
    margin: -1px 5px 0 0;
  }

  img.img--logo {
    width: 150px;
    /* margin-top: -23px; */
  }

  .mb--login .line a p {
    margin-top: 3px;
  }
}

@media screen and (max-width: 300px) {
  .mb--login .imgHeaderBgBtnLine {
    height: 22px;
  }
}

@media screen and (max-width: 293px) {
  img.img--logo {
    width: 125px;
    margin: -8px 0 0 8px;
  }
}